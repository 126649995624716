export const affiliateOfferZones = {
    EXIT_MODAL: "exitModal",
    THANK_YOU: "thankYou",
    HOME_PAGE: "homePage",
    LISTING_PAGE: "listingPage",
    COMPARISON_PAGE: "comparisonPage",
    LOCATION_BANNER: "locationBanner",
    PAGE_BUILDER: "pageBuilder",
    SWITCH_UI_LISTING: "switchUiListing",
} as const;

export type AffiliateOfferZone =
    typeof affiliateOfferZones[keyof typeof affiliateOfferZones];

export const SHARED_FIELDS_CODENAME = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
    "streetAddress",
    "zipCode",
    "companyName",
];
export const COMMON_FIELDS_CODENAME = [
    "streetAddress",
    "zipCode",
    "companyName",
];
